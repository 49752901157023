import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tag",
      "style": {
        "position": "relative"
      }
    }}>{`Tag`}</h1>
    <p>{`O componente é indicado para casos onde é necessário categorizar, organizar ou nomear componentes utilizando palavras-chave para descrevê-los. Tags proporcionam um rápido reconhecimento e navegação.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Utilize o método `}<em parentName="li">{`truncate`}</em>{` quando o texto atingir o tamanho de 200px.`}</li>
    </ul>
    <h2 {...{
      "id": "tag-padrao",
      "style": {
        "position": "relative"
      }
    }}>{`Tag padrão`}</h2>
    <Demo src='pages/components/tag/TagDefault' mdxType="Demo" />
    <h2 {...{
      "id": "tag-com-icone",
      "style": {
        "position": "relative"
      }
    }}>{`Tag com ícone`}</h2>
    <Demo src='pages/components/tag/TagIcon' mdxType="Demo" />
    <h2 {...{
      "id": "tag-removivel",
      "style": {
        "position": "relative"
      }
    }}>{`Tag removível`}</h2>
    <Demo src='pages/components/tag/TagRemovable' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      